import React, { useState } from 'react';
import Style from '../assets/css/calendar.module.css';

const Calendar = () => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const [isMonthSelectorOpen, setIsMonthSelectorOpen] = useState(false);
    const [isYearSelectorOpen, setIsYearSelectorOpen] = useState(false);

    const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dates = Array(firstDayOfMonth)
        .fill(null)
        .concat(
            Array.from({ length: daysInMonth(currentMonth, currentYear) }, (_, i) => i + 1)
        );

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];

    const years = Array.from({ length: 101 }, (_, i) => currentYear - 50 + i);

    const handleMonthClick = (monthIndex) => {
        setCurrentMonth(monthIndex);
        setIsMonthSelectorOpen(false);
    };

    const handleYearClick = (year) => {
        setCurrentYear(year);
        setIsYearSelectorOpen(false);
    };

    const isToday = (date) =>
        date === today.getDate() &&
        currentMonth === today.getMonth() &&
        currentYear === today.getFullYear();

    return (
        <div className={Style.calendarContainer}>
            <header className={Style.calendarHeader}>
                <button className={Style.navButton} onClick={() => setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1))}>
                    &lt;
                </button>

                <div className={Style.currentMonthYear}>
                    <span
                        className={Style.monthSelector}
                        onClick={() => setIsMonthSelectorOpen((prev) => !prev)}
                    >
                        {months[currentMonth]}
                    </span>
                    {' '}
                    <span
                        className={Style.yearSelector}
                        onClick={() => setIsYearSelectorOpen((prev) => !prev)}
                    >
                        {currentYear}
                    </span>
                </div>

                <button className={Style.navButton} onClick={() => setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1))}>
                    &gt;
                </button>
            </header>

            {isMonthSelectorOpen && (
                <div className={Style.dropdown}>
                    {months.map((month, index) => (
                        <div
                            key={index}
                            className={Style.dropdownItem}
                            onClick={() => handleMonthClick(index)}
                        >
                            {month}
                        </div>
                    ))}
                </div>
            )}

            {isYearSelectorOpen && (
                <div className={Style.dropdown}>
                    {years.map((year) => (
                        <div
                            key={year}
                            className={Style.dropdownItem}
                            onClick={() => handleYearClick(year)}
                        >
                            {year}
                        </div>
                    ))}
                </div>
            )}

            <div className={Style.daysOfWeek}>
                {daysOfWeek.map((day, index) => (
                    <div key={index} className={Style.dayOfWeek}>
                        {day}
                    </div>
                ))}
            </div>
            <div className={Style.datesGrid}>
                {dates.map((date, index) => (
                    <div
                        key={index}
                        className={`${Style.date} ${
                            isToday(date) ? Style.today : ''
                        } ${date === null ? Style.empty : ''}`}
                    >
                        {date || ''}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Calendar;
