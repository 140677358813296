import React, { useState, useEffect } from 'react';
import { FaBatteryFull } from "react-icons/fa6";
import Style from '../assets/css/clock.module.css';

const Clock = () => {
    const [time, setTime] = useState(new Date());
    const [isYellow, setIsYellow] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatDate = (date) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const day = days[date.getDay()];
        const dateNum = date.getDate();
        const month = months[date.getMonth()];
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'p.m.' : 'a.m.';

        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${day} ${dateNum} ${month} ${hours}:${formattedMinutes} ${ampm}`;
    };

    const toggleColor = () => {
        setIsYellow(!isYellow);
    };

    return (
        <div className={Style.clock}>
            <FaBatteryFull
                onClick={toggleColor}
                style={{ fill: isYellow ? 'yellow' : 'white' }}
            />
            {formatDate(time)}
        </div>
    );
};

export default Clock;
