import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Style from '../assets/css/mail.module.css';

function Mail() {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [phone, setPhone] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Inicializa EmailJS con tu clave pública
        emailjs.init('1wQ5hcO2lkSg1UWH6');

        // Crea un objeto con los datos del formulario
        const formData = {
            email,
            subject,
            message: body,
            phone,
        };

        // Envía el formulario a través de EmailJS
        emailjs.send("service_7ac3amo", "template_1ngo741", {
            from_name: formData.email,
            subject: formData.subject,
            message: formData.message,
            phone: formData.phone,
        })
        .then((response) => {
            Swal.fire(
                'Email sent!',
                'Your email was sent successfully!',
                'success'
            );

            setEmail('');
            setSubject('');
            setBody('');
            setPhone('');
        }, (error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong, email could not be sent!'
            });
        });
    };

    return (
        <div className={Style.mailContainer}>
            <form onSubmit={handleSubmit} className={Style.mailForm}>
                <div className={Style.formGroup}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Type your email here'
                        autoComplete='off'
                    />
                </div>
                <div className={Style.formGroup}>
                    <label htmlFor="subject">Subject:</label>
                    <input
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        placeholder='Type the subject here'
                    />
                </div>
                <div className={Style.formGroup}>
                    <label htmlFor="phone">Phone Number:</label>
                    <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        placeholder='Type your phone number here'
                    />
                </div>
                <div className={Style.formGroup}>
                    <label htmlFor="body">Body:</label>
                    <textarea
                        type="text"
                        id="body"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        required
                        placeholder='Type your message here'
                    />
                </div>
                <button className={Style.mailButton} type="submit">Send Email</button>
            </form>
        </div>
    );
}

export default Mail;
