import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Lockscreen from './components/lockscren';
import Landing from './components/landing';
import Error from './components/error';
import './App.css';

function App() {

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Lockscreen />} />
        <Route path="/landing" element={<Landing />} />
        <Route
          path="*"
          element={
            <>
              <Error />
              <Navigate to="/not-found" replace />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;