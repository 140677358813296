import React from 'react'
import Style from '../assets/css/cv.module.css'

function Cv() {
    return (
        <div className={Style.pdfContainer}>
            <iframe
                src="Omar-CV.pdf"
                title="PDF Viewer"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
            ></iframe>
        </div>
    )
}

export default Cv
