import React, { useState, useEffect } from 'react'
import { db } from '../../firebase.js';
import { collection, addDoc, onSnapshot, doc, getDoc, setDoc } from 'firebase/firestore';
import Style from '../assets/css/iMessage.module.css'

function Message() {

    const [messages, setMessages] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [userName, setUserName] = useState('');
    const [message, setMessage] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [userNameInput, setUserNameInput] = useState('Anonymus');
    const [colorInput, setColorInput] = useState('');
    const currentUser = userName;

    const handleSendMessage = async () => {
        try {
            await addDoc(collection(db, 'messages'), {
                name: userName,
                message: message,
                color: selectedColor,
                timestamp: new Date().toISOString(),
            });
            setMessage('');
        } catch (error) {
            console.error('Error al enviar el mensaje:', error);
        }
    };
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'messages'), (snapshot) => {
            const fetchedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                timestamp: new Date(doc.data().timestamp).toLocaleString(),
            }));

            const sortedMessages = fetchedMessages.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

            setMessages(sortedMessages);
        });

        return () => unsubscribe();
    }, []);

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleSaveUserNameAndColor = () => {
        setUserName(userNameInput);
        setSelectedColor(colorInput);
        setShowForm(false);
    };

    const isSendButtonDisabled = !message || selectedColor === '';   

    return (
        <>
            <header className={Style.header}>
                <h2>General Chat</h2>
                {!showForm && (
                    <button onClick={handleShowForm} className={Style.showFormButton}>
                        Name & Color
                    </button>
                )}
                {showForm && (
                    <div className={Style.nameAndColor}>
                        <input
                            type="text"
                            placeholder="Escribe tu nombre..."
                            value={userNameInput}
                            onChange={(e) => setUserNameInput(e.target.value)}
                            className={Style.messageInput}
                        />
                        <div className={Style.colorSave}>
                            <select
                                value={colorInput}
                                onChange={(e) => setColorInput(e.target.value)}
                                className={Style.colorSelect}
                            >
                                <option disabled value="">Select a color</option>
                                <option value="#ff3030">Red</option>
                                <option value="#00ff00">Green</option>
                                <option value="#3280ff">Blue</option>
                                <option value="#ffff00">Yellow</option>
                                <option value="#ff8dff">Pink</option>
                                <option value="#00ffff">Cyan</option>
                            </select>
                            <button
                                onClick={handleSaveUserNameAndColor}
                                className={Style.saveButton}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </header>
            <div className={Style.messageList}>
                {messages.map((msg) => (
                    <div
                        key={msg.id}
                        className={`${Style.message} ${msg.name === currentUser ? Style.sent : Style.received}`}
                    >
                        <span className={Style.userName}>{msg.name}</span>
                        <p className={Style.messageText} style={{ backgroundColor: msg.color }}>{msg.message}</p>
                        <span className={Style.timestamp}>{msg.timestamp}</span>
                    </div>
                ))}
            </div>
            <footer className={Style.footeriMessage}>
                <input
                    placeholder="Write a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className={Style.messageInput}
                />
                <button
                    className={Style.sendButton}
                    onClick={handleSendMessage}
                    disabled={isSendButtonDisabled}
                >
                    Send
                </button>
            </footer>
        </>
    )
}

export default Message
