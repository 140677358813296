import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import Youtube from './youtube.jsx';
import Cv from './cv.jsx';
import Message from './iMessage.jsx';
import Mail from './mail.jsx';
import Terminal from './terminal.jsx';
import Settings from './settings.jsx';
import Music from './music.jsx';
import Calendar from './calendar.jsx';
import Calculator from './calculator.jsx';
import Style from '../assets/css/footer.module.css';

function Footer({ handleFinderClick }) {
    const [loadingImages, setLoadingImages] = useState({});
    const [modalsOpen, setModalsOpen] = useState({});
    const [zIndexes, setZIndexes] = useState({});
    const [backgroundImage, setBackgroundImage] = useState('');

    const maxZIndex = Math.max(1, ...Object.values(zIndexes));

    const bringToFront = (imgKey) => {
        setZIndexes((prevState) => ({
            ...prevState,
            [imgKey]: maxZIndex + 1,
        }));
    };

    const handleImageClick = (imgKey) => {
        if (modalsOpen[imgKey]) {
            bringToFront(imgKey);
            return;
        }

        setModalsOpen((prevState) => ({ ...prevState, [imgKey]: true }));
        setLoadingImages((prevState) => ({ ...prevState, [imgKey]: true }));

        setTimeout(() => {
            setLoadingImages((prevState) => ({ ...prevState, [imgKey]: false }));
        }, 500);

        bringToFront(imgKey);
    };

    useEffect(() => {
        if (handleFinderClick) {
            handleFinderClick(() => handleImageClick("finder"));
        }
    }, [handleFinderClick]);

    const handleCloseModal = (imgKey) => {
        setModalsOpen((prevState) => ({ ...prevState, [imgKey]: false }));
    };

    const appData = [
        { key: 'finder', src: 'finder.webp', title: 'Omar A. Cruz Rmz. CV', component: <Cv /> },
        { key: 'safari', src: 'safari.webp', title: 'Safari', component: <Youtube /> },
        { key: 'iMessage', src: 'iMessage.webp', title: 'iMessage', component: <Message /> },
        { key: 'mail', src: 'mail.webp', title: 'Mail', component: <Mail /> },
        { key: 'music', src: 'music.webp', title: 'Music', component: <Music /> },
        { key: 'calendar', src: 'calendar.webp', title: 'Calendar', component: <Calendar /> },
        { key: 'calculator', src: 'calculator.webp', title: 'Calculator', component: <Calculator /> },
        { key: 'terminal', src: 'terminal.webp', title: 'Terminal', component: <Terminal onClose={() => handleCloseModal('terminal')} /> },
        { key: 'settings', src: 'settings.webp', title: 'Settings', component: <Settings setBackgroundImage={setBackgroundImage} />},
    ];

    return (
        <>
            <footer className={Style.footer}>
                <div className={Style.apps}>
                    {appData.map(({ key, src }) => (
                        <div key={key} className={Style[key]}>
                            <img
                                src={src}
                                alt={key}
                                className={loadingImages[key] ? Style.loading : ''}
                                onClick={() => handleImageClick(key)}
                                onTouchStart={() => handleImageClick(key)}
                                width="128"
                                height="128"
                                loading="lazy"
                                draggable={false}
                            />
                            <div className={Style.circle}></div>
                        </div>
                    ))}
                </div>
            </footer>

            {appData.map(({ key, title, component }) => (
                modalsOpen[key] && (
                    <Draggable
                        key={key}
                        bounds={{ top: 0 }}
                        cancel=".noDrag" 
                    >
                        <div className={`${Style[key + 'Modal']}`} style={{ zIndex: zIndexes[key] || 1 }}>
                            <div className={Style.modal} 
                                onClick={() => bringToFront(key)}
                            >
                                <div className={`${Style.circlesContainer} dragHandle`}>
                                    <div 
                                        className={Style.circles} 
                                        onClick={() => handleCloseModal(key)} 
                                        onTouchEnd={() => handleCloseModal(key)} 
                                    ></div>
                                    <div className={Style.circles}></div>
                                    <div className={Style.circles}></div>
                                </div>
                                <h2 style={{ color: 'white', marginTop: '1rem', marginBottom: '0.25rem', fontSize: '2rem' }}>{title}</h2>
                                <div className="noDrag">{component}</div> 
                            </div>
                        </div>
                    </Draggable>
                )
            ))}
        </>
    );
}

export default Footer;
