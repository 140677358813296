import React, { Suspense, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import Clock from './clock';
import Draggable from 'react-draggable';
import Style from '../assets/css/navbar.module.css';
import Swal from 'sweetalert2'

function Logo() {
    const { scene } = useGLTF('apple_logo/scene.gltf');
    const ref = useRef();

    useFrame(() => {
        if (ref.current) {
            ref.current.rotation.y += 0.025;
        }
    });

    scene.scale.set(2, 2, 2);

    return <primitive ref={ref} object={scene} />;
}

function Modal({ isOpen, onClose, onAboutMeOpen }) {
    const modalRef = useRef();

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    const handleTextClick = () => {
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, shut down!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Bye!",
                    text: "Shuting down the computer.",
                    icon: "success"
                }).then(() => {
                    window.location.href = 'https://www.google.com';
                });
            }
        });
        onClose();
    };

    const handleAboutMeClick = () => {
        onClose();
        onAboutMeOpen();
    };

    React.useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={Style.modal} onClick={onClose}>
            <div className={Style.modalContent} ref={modalRef} onClick={(e) => e.stopPropagation()}>
                <p onClick={handleAboutMeClick}>System Info</p>
                <hr className={Style.separator} />
                <Link to="/" className={Style.link}><p>Lock Screen</p></Link>
                <hr className={Style.separator} />
                <p onClick={handleTextClick}>Shut Down...</p>
            </div>
        </div>
    );
}

function AboutMeModal({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <Draggable bounds={{ top: 0 }} cancel=".redes">
            <div className={Style.modal2}>
                <div className={Style.modalContent2}>
                    <div className={Style.circlesContainer}>
                        <div className={Style.circle} onClick={onClose} onTouchStart={onClose}></div>
                        <div className={Style.circle}></div>
                        <div className={Style.circle}></div>
                    </div>
                    <div className={Style.modalImg}>
                        <img src="yoMemoji.webp" alt="Omar Cruz" draggable={false} />
                    </div>
                    <div className={Style.modal2Title}>
                        <h1>Omar Alfonso Cruz Ramírez</h1>
                        <p>Front-End & Mobile Developer</p>
                    </div>
                    <div className={Style.socialIcons}>
                        <SocialIcon url="https://github.com/Damanger" network="github" title="GitHub" className="redes" target="_blank" fgColor="#fff" style={{ height: 28, width: 28 }} onTouchStart={() => window.open("https://github.com/Damanger", "_blank")} />
                        <SocialIcon url='https://wa.me/529514084765/?text=Hola,%20buen%20día,%20quisiera%20contratar%20sus%20servicios%20:).' title="WhatsApp" network="whatsapp" className="redes" target="_blank" fgColor="#fff" style={{ height: 28, width: 28 }} onTouchStart={() => window.open("https://wa.me/529514084765/?text=Hola,%20buen%20día,%20quisiera%20contratar%20sus%20servicios%20:).", "_blank")} />
                        <SocialIcon url="https://www.linkedin.com/in/omar-cruzr97/" network="linkedin" title="LinkedIn" className="redes" target="_blank" fgColor="#fff" style={{ height: 28, width: 28 }} onTouchStart={() => window.open("https://www.linkedin.com/in/omar-cruzr97/", "_blank")} />
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

function Navbar({ openFinder }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAboutMeModalOpen, setAboutMeModalOpen] = useState(false);

    const handleCanvasClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleAboutMeOpen = () => {
        setAboutMeModalOpen(true);
    };

    const handleCloseAboutMeModal = () => {
        setAboutMeModalOpen(false);
    };

    return (
        <>
            <nav className={Style.navbar}>
                <div className={Style.menuLogo}>
                    <span>
                    <Canvas className={Style.canvas} onClick={handleCanvasClick}>
                        <PerspectiveCamera makeDefault position={[0, 1.5, 5]} fov={50} />
                        <Suspense fallback={null}>
                            <Logo />
                        </Suspense>
                    </Canvas>
                    <p onClick={openFinder}>Finder</p>
                    </span>
                </div>
                <p>Dev. Omar A. Cruz Rmz.</p>
                <Clock />
            </nav>

            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onAboutMeOpen={handleAboutMeOpen}
            />

            <AboutMeModal
                isOpen={isAboutMeModalOpen}
                onClose={handleCloseAboutMeModal}
            />
        </>
    );
}

export default Navbar;
