import React, { useState, useEffect, useRef } from 'react';
import Style from '../assets/css/music.module.css';
import Swal from 'sweetalert2';

const Music = () => {
    const [favouriteSong, setFavouriteSong] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const audioRefs = useRef([]);

    useEffect(() => {
        if (favouriteSong) {
            setLoading(true);

            // Limpiar los audios previos
            audioRefs.current = []; // Resetear el array de referencias

            fetch(`https://itunes.apple.com/search?term=${favouriteSong}&limit=10`)
                .then(response => response.json())
                .then(data => {
                    setResults(data.results); // Setear los resultados aquí
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [favouriteSong]); // Esta dependencia asegura que se vuelva a ejecutar solo cuando la canción favorita cambie

    const handleSearch = () => {
        // Mostrar SweetAlert para pedir la canción favorita
        Swal.fire({
            title: 'What is your favourite song, artist, album, or movie?',
            input: 'text',
            inputPlaceholder: 'Enter a song, artist, album or movie...',
            showCancelButton: true,
            confirmButtonText: 'Search',
            cancelButtonText: 'Cancel',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter something!';
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setFavouriteSong(result.value);
                setResults([]); 
            }
        });
    };

    return (
        <>
            <button className={Style.buttonM} onClick={handleSearch} >
                <svg className={Style.svgIcon} viewBox="0 0 512 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path>
                </svg>
                Search your favourite song, artist, album or movie
            </button>

            {loading && <p className={Style.loadingM}></p>}

            <ol className={Style.resultsList}>
                {results.length > 0 && results.map((song, index) => (
                    <li key={index} className={Style.liM} style={{ backgroundColor: '#f1f1f1', padding: '10px', borderRadius: '8px' }}>
                        <h1 className={Style.h1M}>{song.kind}</h1>
                        <img src={song.artworkUrl100} alt={song.trackName} style={{ width: '100px', height: '100px', borderRadius: '5px' }} />
                        <h2>{song.trackName}</h2>
                        <h3>{song.artistName}</h3>
                        <audio
                            ref={(el) => audioRefs.current[index] = el} 
                            controls
                            preload="none"
                            className={Style.audioM}
                        >
                            <source src={song.previewUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </li>
                ))}
            </ol>
        </>
    );
};

export default Music;
