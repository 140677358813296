import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Style from '../assets/css/lockscreen.module.css';

function Lockscreen() {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' || event.code === 'Space') {
                startAnimation();
            }
        };

        const handleTouch = () => {
            startAnimation();
        };

        const updateClock = () => {
            setCurrentTime(new Date());
        };

        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('touchstart', handleTouch);

        const intervalId = setInterval(updateClock, 1000);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('touchstart', handleTouch); 
            clearInterval(intervalId);
        };
    }, [navigate]);

    const startAnimation = () => {
        setIsAnimating(true);
        setTimeout(() => {
            navigate('/landing');
        }, 1000);
    };

    const formatDate = (date) => {
        const dateOptions = {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
        };
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
        const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

        return { formattedDate, formattedTime };
    };

    const { formattedDate, formattedTime } = formatDate(currentTime);

    return (
        <div className={`${Style.lockscreen} ${isAnimating ? Style.animating : ''}`}>
            <div className={Style.clock}>
                <div className={Style.date}>{formattedDate}</div>
                <div className={Style.time}>{formattedTime}</div>
            </div>
            <div className={Style.sectionBanner}>
                <div id={Style.star1}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star2}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star3}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star4}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star5}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star6}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>

                <div id={Style.star7}>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerBottomright}></div>
                        <div id={Style.curvedCornerBottomleft}></div>
                    </div>
                    <div className={Style.curvedCornerStar}>
                        <div id={Style.curvedCornerTopright}></div>
                        <div id={Style.curvedCornerTopleft}></div>
                    </div>
                </div>
            </div>
            <h1 className={Style.lockscreenTitle}>Press enter, spacebar, or tap to continue</h1>
        </div>
    );
}

export default Lockscreen;
