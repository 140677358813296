import React from 'react';
import Style from '../assets/css/certifications.module.css';

const Certifications = () => {
    const certifications = [
        { imgSrc: 'NASA.webp', caption: 'NASA\'s Hackaton', url: 'https://www.xeouradio.com/2024/10/14/andivi-nundoo-el-proyecto-que-llevo-a-alumnos-y-egresados-de-la-utm-a-ser-finalistas-de-la-nasa-space-apps-challenge-oaxaca/' },
        { imgSrc: 'CS.webp', caption: 'Computer Science - CertiProf', url: 'https://www.credly.com/badges/bc20b658-2c62-4147-8034-c42946a02737' },
        { imgSrc: 'DS.webp', caption: 'Data Science - Oracle', url: 'https://app.aluracursos.com/certificate/793f2ccb-f774-4eb6-9021-f68b104b4361' },
        { imgSrc: 'ASU.webp', caption: 'English for the Semiconductor Industry  - Arizona State University', url: 'https://semiconductores.udg.mx/mod/customcert/verify_certificate.php?code=kJRpRMw9MJ&qrcode=1' },
        { imgSrc: 'FRONT.webp', caption: 'Frontend Developer - Oracle', url: 'https://app.aluracursos.com/degree/certificate/50b430ee-3e4d-4d7d-b6a7-cee3ef15c28d' },
        { imgSrc: 'OC.webp', caption: 'Oracle Cloud - Oracle', url: 'https://app.aluracursos.com/certificate/eba9250c-b353-40b0-a4a4-7937514b74ff' },
        { imgSrc: 'SCRUM.webp', caption: 'Scrum Master - CertiProf', url: 'https://www.credly.com/badges/eac4d0b2-69c4-4853-b434-7c0ada9e4b95/public_url' },
        { imgSrc: 'SQL.webp', caption: 'SQL MySQL - Oracle', url: 'https://app.aluracursos.com/certificate/2fa84633-0eae-4e2f-bfd9-2347173a5c03' },
        { imgSrc: 'SQLHR.webp', caption: 'SQL - HackerRank', url: 'https://www.hackerrank.com/certificates/6d532874392b' },
    ];

    return (
        <div className={Style.certificationsContainer}>
            {certifications.map((cert, index) => (
                <div className={Style.certification} key={index}>
                    <div className="card" style={{
                        backdropFilter: 'blur(0px) saturate(100%)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '200px', height: '160px',
                        margin: '0 auto', borderRadius: '8px', zIndex: '1', marginBottom: '1rem',
                    }}>
                        <a href={cert.url} target="_blank" rel="noreferrer" className={Style.certificationLink}>
                            <img src={cert.imgSrc} alt={cert.caption} />
                            <p className={Style.caption}>{cert.caption}</p>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Certifications;
