import React, { useState } from 'react';
import Style from '../assets/css/education.module.css';

const Education = () => {
    const [selectedYear, setSelectedYear] = useState(null);

    const events = [
        { year: 2020, description: 'Started at Universidad Tecnológica de la Mixteca' },
        { year: 2021, description: 'Worked with databases' },
        { year: 2022, description: 'Oracle Next Education' },
        { year: 2023, description: 'Laboratory of Advanced Image Processing (LAPI) - U.N.A.M.' },
        { year: 2024, description: 'HCI, Visualization & Graphics, Halifax, Nova Scotia Canada - Dalhousie University & NASA International Space Apps Challenge Event Top 9 localy' },
        { year: 2025, description: 'Graduation' },
    ];

    const handleClick = (year) => {
        setSelectedYear(year === selectedYear ? null : year);
    };

    return (
        <div className={Style.timelineContainer}>
            <div className={Style.line}></div>
            {events.map((event, index) => (
                <div
                    key={index}
                    className={Style.eventContainer}
                    onClick={() => handleClick(event.year)}
                    onTouchStart={() => handleClick(event.year)}
                >
                    <div className={`${Style.circle} ${selectedYear === event.year ? Style.selectedCircle : ''}`}></div>
                    <div className={Style.year}>{event.year}</div>
                    {selectedYear === event.year && (
                        <div className={Style.description}>{event.description}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Education;
