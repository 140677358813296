import React, { useState, useEffect } from 'react';
import Style from '../assets/css/settings.module.css';
import { getDatabase, ref, set, onValue } from 'firebase/database';

function Settings({ setBackgroundImage }) {
    const images = [
        {
            thumbnail: 'https://images.pexels.com/photos/1323550/pexels-photo-1323550.jpeg?auto=compress&cs=tinysrgb&w=640&h=412',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-8moments-1323550.jpg?alt=media&token=90274937-934d-4a7b-adf7-47375adce4bb'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/635279/pexels-photo-635279.jpeg?auto=compress&cs=tinysrgb&w=640&h=425',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-bella-white-201200-635279.jpg?alt=media&token=71a333d8-13fc-4a10-99be-ba3af41c3e23'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/847393/pexels-photo-847393.jpeg?auto=compress&cs=tinysrgb&w=640&h=480',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-belle-co-99483-847393.jpg?alt=media&token=929c59b0-0c28-4bfa-aaa0-43ccd6824a65'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/956981/milky-way-starry-sky-night-sky-star-956981.jpeg?auto=compress&cs=tinysrgb&w=640&h=360',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-felixmittermeier-956981.jpg?alt=media&token=9f75bb89-0605-4024-ad1c-b525247f5412'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/395196/pexels-photo-395196.jpeg?auto=compress&cs=tinysrgb&w=640&h=427',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-gabriela-palai-129458-395196.jpg?alt=media&token=69364b8f-9976-4506-a2db-643eaf425575'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/1435075/pexels-photo-1435075.jpeg?auto=compress&cs=tinysrgb&w=640&h=360',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-jplenio-1435075.jpg?alt=media&token=68107abe-e91e-4d36-88b2-f440854d187b'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/1169754/pexels-photo-1169754.jpeg?auto=compress&cs=tinysrgb&w=640&h=427',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-philippedonn-1169754.jpg?alt=media&token=0df6073b-6381-42f1-a518-8e4daa9aa7dd'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/262325/pexels-photo-262325.jpeg?auto=compress&cs=tinysrgb&w=640&h=424',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-pixabay-262325.jpg?alt=media&token=24c44e06-4f7a-49d0-b6cd-df0244e329bb'
        },
        {
            thumbnail: 'https://images.pexels.com/photos/39811/pexels-photo-39811.jpeg?auto=compress&cs=tinysrgb&w=640&h=427',
            highQuality: 'https://firebasestorage.googleapis.com/v0/b/portfolio-f2373.appspot.com/o/pexels-veeterzy-39811.jpg?alt=media&token=bf9073df-9a34-462a-9e6a-3c03e6710361'
        }
    ];

    const [currentBackgroundImage, setCurrentBackgroundImage] = useState('');

    useEffect(() => {
        const db = getDatabase();
        const imageRef = ref(db, '/wallpaper/imageUrl');
        
        // Obtener la imagen de fondo actual de la base de datos
        onValue(imageRef, (snapshot) => {
            const data = snapshot.val();
            setCurrentBackgroundImage(data);
        });
    }, []);

    const handleImageClick = (highQualityImage) => {
        setBackgroundImage(highQualityImage);
        updateDatabase(highQualityImage);
    };

    const updateDatabase = (image) => {
        const db = getDatabase();
        const imageRef = ref(db, '/wallpaper/imageUrl');

        set(imageRef, image)
            .then(() => {
                console.log('Imagen actualizada en la base de datos');
            })
            .catch((error) => {
                console.error('Error al actualizar la imagen: ', error);
            });
    };

    return (
        <div className={Style.imageGallery}>
            {images.map((image, index) => (
                <div 
                    key={index} 
                    className={`${Style.imageContainer} ${currentBackgroundImage === image.highQuality ? Style.active : ''}`} 
                    onClick={() => handleImageClick(image.highQuality)}
                >
                    <img src={image.thumbnail} alt={`Thumbnail ${index + 1}`} className={Style.thumbnail} />
                </div>
            ))}
        </div>
    );
}

export default Settings;
