import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import Projects from './projects';
import Education from './education';
import Certifications from './certifications';
import Carousel from './carousel';
import Sponsor from './sponsor';
import Style from '../assets/css/me.module.css';

function Me() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(true);
    const [isSelected, setIsSelected] = useState(false);
    const [lastTouchTime, setLastTouchTime] = useState(0);
    const [activeSection, setActiveSection] = useState('About Me')
    const folderRef = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
        setDragEnabled(false);
        setIsSelected(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDragEnabled(true);
    };

    const handleSingleClick = () => {
        setIsSelected(true);
    };

    const handleDoubleClick = () => {
        openModal();
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const handleTouch = () => {
        const currentTime = new Date().getTime();
        const timeSinceLastTouch = currentTime - lastTouchTime;

        if (timeSinceLastTouch < 300 && timeSinceLastTouch > 0) {
            handleDoubleClick();
        } else {
            handleSingleClick();
        }
        setLastTouchTime(currentTime);
    };

    const handleClickOutside = (event) => {
        if (folderRef.current && !folderRef.current.contains(event.target)) {
            setIsSelected(false);
        }
    };

    useEffect(() => {
        if (activeSection === 'About Me') {
            setIsModalOpen(true);
        }
    }, [activeSection]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    const renderContent = () => {
        switch (activeSection) {
            case 'About Me':
                return (
                    <>
                        <div className={Style.meContainer}>
                            <img 
                                src='./yo.webp' 
                                alt="About Me" 
                                width="300" 
                                height="300" 
                                loading="lazy" 
                                className={Style.aboutImage}
                            />
                        </div>
                        <div className={Style.aboutContent}>
                            <h1>Hello and welcome to my website! My name is Omar Alfonso Cruz Ramírez a <span className={Style.jr}>FullStack</span><span className={Style.mw}> Mobile-Web Developer</span>.</h1>
                            <p><strong>Location:</strong> Mexico 🇲🇽</p>
                        </div>
                    </>
                );
            case 'Projects':
                return <Projects />;
            case 'Education':
                return <Education />;
            case 'Certifications':
                return <Certifications />;
            case 'Skills':
                return <Carousel />;
            case 'Sponsor':
                return <Sponsor />;
            default:
                return null;
        }
    };

    return (
        <>
            <Draggable
                disabled={!dragEnabled}
                bounds={{ top: 0 }}>
                <div
                    ref={folderRef}
                    className={`${Style.iconContainer} ${isSelected ? Style.selected : ''}`}
                    onClick={handleSingleClick}
                    onDoubleClick={handleDoubleClick}
                    onTouchStart={handleTouch}
                >
                    <img src="folder.webp" alt="Folder Icon" className={Style.icon} draggable={false} />
                    <span className={Style.title}>Me</span>
                </div>
            </Draggable>

            {isModalOpen && (
                <div className={Style.modal} style={{ zIndex: 9999 }}>
                    <Draggable
                        grid={[1, 1]}
                        scale={1}
                        axis="both"
                        bounds={{ top: 0 }}
                        defaultPosition={{
                            x: 0,
                            y: 0,
                        }}
                    >
                        <div className={Style.modalContent}>
                            <aside>
                                <ul>
                                    <li
                                        onClick={() => handleSectionClick('About Me')}
                                        onTouchStart={() => handleSectionClick('About Me')}
                                        className={activeSection === 'About Me' ? Style.active : ''}>
                                        About Me
                                    </li>
                                    <li
                                        onClick={() => handleSectionClick('Projects')}
                                        onTouchStart={() => handleSectionClick('Projects')}
                                        className={activeSection === 'Projects' ? Style.active : ''}>
                                        Projects
                                    </li>
                                    <li
                                        onClick={() => handleSectionClick('Education')}
                                        onTouchStart={() => handleSectionClick('Education')}
                                        className={activeSection === 'Education' ? Style.active : ''}>
                                        Education
                                    </li>
                                    <li
                                        onClick={() => handleSectionClick('Certifications')}
                                        onTouchStart={() => handleSectionClick('Certifications')}
                                        className={activeSection === 'Certifications' ? Style.active : ''}>
                                        Certifications
                                    </li>
                                    <li
                                        onClick={() => handleSectionClick('Skills')}
                                        onTouchStart={() => handleSectionClick('Skills')}
                                        className={activeSection === 'Skills' ? Style.active : ''}>
                                        Skills
                                    </li>
                                    <li
                                        onClick={() => handleSectionClick('Sponsor')}
                                        onTouchStart={() => handleSectionClick('Sponsor')}
                                        className={activeSection === 'Sponsor' ? Style.active : ''}>
                                        Sponsor
                                    </li>
                                </ul>
                            </aside>
                            <div className={Style.circlesContainer}>
                                <div className={Style.circles} onClick={closeModal} onTouchStart={closeModal}></div>
                                <div className={Style.circles}></div>
                                <div className={Style.circles}></div>
                            </div>
                            <div className={Style.contentProjects}>
                                <div className={Style.titleMe}>
                                    <h2 style={{ textAlign: 'center' }}>{activeSection}</h2>
                                </div>
                                <hr className={Style.separator} />
                                {renderContent()}
                            </div>
                        </div>
                    </Draggable>
                </div>
            )}
        </>
    );
}

export default Me;
