import React, { useEffect, useState, useCallback  } from 'react';
import Navbar from './navbar';
import Footer from './footer';
import Projects from './projects';
import Me from './me'
import Style from '../assets/css/landing.module.css';
import { getDatabase, ref, onValue } from 'firebase/database';

function Landing() {
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        const db = getDatabase();
        const imageRef = ref(db, 'wallpaper/imageUrl');

        onValue(imageRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setBackgroundImage(data);
            }
        });
    }, []);

    const [openFinder, setOpenFinder] = useState(() => () => {});

    const handleFinderClick = useCallback((finderClickFunction) => {
        setOpenFinder(() => finderClickFunction);
    }, []);

    return (
        <>
            <div
                className={Style.imageBackground}
                style={{ backgroundImage: `url(${backgroundImage})` }}
            ></div>
            <Navbar openFinder={() => openFinder("finder")}/>
            <Projects />
            <Me />
            <Footer handleFinderClick={handleFinderClick}/>
        </>
    );
}

export default Landing;
