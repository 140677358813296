import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Style from '../assets/css/terminal.module.css';

function Terminal({ onClose }) {
    const [inputValue, setInputValue] = useState('');
    const [showMinesweeper, setShowMinesweeper] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue === '1') {
            setShowMinesweeper(true);
        } else if (inputValue === '2') {
            onClose();
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid command! Please enter '1' to play Minesweeper or '2' to close the terminal.",
            });
        }
        setInputValue('');
    };

    return (
        <div className={Style.terminalContainer}>
            {showMinesweeper ? (
                <>Game under construction</>
            ) : (
                <div>
                    <h1>Menu</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Enter "1" to play a game or "2" to close the terminal:
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <button className={Style.termButton} type="submit">Submit</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Terminal;
