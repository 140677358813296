// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB9Y6Nke1fVaajiShF59ovBvFdzf5ibW0o",
    authDomain: "portfolio-f2373.firebaseapp.com",
    projectId: "portfolio-f2373",
    storageBucket: "portfolio-f2373.appspot.com",
    messagingSenderId: "120756132880",
    appId: "1:120756132880:web:173a60e44e2a42e0db7e95",
    measurementId: "G-05CYY9PMP5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
