import React, { useState, useEffect } from 'react';
import { FaCalculator } from 'react-icons/fa';
import { LuDelete } from "react-icons/lu";
import { BsPlusSlashMinus } from "react-icons/bs";
import axios from 'axios';
import Style from '../assets/css/calculator.module.css';

const Calculator = () => {
    const [input, setInput] = useState(''); // Entrada de la calculadora
    const [convertedAmount, setConvertedAmount] = useState(''); // Monto convertido
    const [hasCalculated, setHasCalculated] = useState(false);
    const [showMenu, setShowMenu] = useState(false); // Controla el menú desplegable
    const [selectedOption, setSelectedOption] = useState('Basic'); // Opción seleccionada
    const [inputCurrency, setInputCurrency] = useState('MXN'); // Moneda de entrada
    const [outputCurrency, setOutputCurrency] = useState('USD'); // Moneda de salida
    const [conversionRates, setConversionRates] = useState({});

    // Función para obtener tasas de conversión en tiempo real
    const fetchConversionRates = async () => {
        try {
            const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${inputCurrency}`);
            setConversionRates(response.data.rates); // Asigna las tasas de conversión obtenidas
        } catch (error) {
            console.error("Error al obtener las tasas de conversión:", error);
        }
    };

    // Cargar tasas de conversión cuando cambian las monedas de entrada o salida
    useEffect(() => {
        fetchConversionRates();
    }, [inputCurrency]);

    // Lógica para manejar la entrada de la calculadora
    const isOperator = (char) => ['+', '-', '*', '/', '%'].includes(char);

    const handleInput = (value) => {
        setInput((prev) => {
            const lastChar = prev.slice(-1);

            if (value === '+/-' && selectedOption === 'Convert') {
                if (hasCalculated) {
                    const newResult = (parseFloat(convertedAmount) * -1).toString();
                    setConvertedAmount(newResult);
                    setHasCalculated(true);
                    return newResult;
                }

                const match = prev.match(/([+\-*/%])?([-+]?\d*\.?\d+)$/);
                if (match) {
                    const operator = match[1] || '';
                    const lastNumber = match[2];
                    const newNumber = lastNumber.startsWith('-')
                        ? lastNumber.slice(1)
                        : `-${lastNumber}`;
                    return prev.slice(0, prev.lastIndexOf(match[0])) + operator + `(${newNumber})`;
                }

                return prev;
            }

            if (value === '+/-' && selectedOption === 'Basic') {
                if (hasCalculated) {
                    const newResult = (parseFloat(input) * -1).toString();
                    return newResult;
                }

                const match = prev.match(/([+\-*/%])?([-+]?\d*\.?\d+)$/);
                if (match) {
                    const operator = match[1] || '';
                    const lastNumber = match[2];
                    const newNumber = lastNumber.startsWith('-')
                        ? lastNumber.slice(1)
                        : `-${lastNumber}`;
                    return prev.slice(0, prev.lastIndexOf(match[0])) + operator + `(${newNumber})`;
                }

                return prev;
            }

            if (hasCalculated) {
                if (isOperator(value)) {
                    setHasCalculated(false);
                    setConvertedAmount(''); // Limpiar la conversión si el usuario empieza a operar
                    return input + value;
                } else {
                    setHasCalculated(false);
                    setConvertedAmount(''); // Limpiar la conversión si el usuario empieza a ingresar números
                    return value;
                }
            }

            if (isOperator(lastChar) && isOperator(value)) {
                return prev.slice(0, -1) + value;
            }

            return prev + value;
        });
    };

    const calculateResult = () => {
        try {
            const calculation = eval(input).toString();
            setInput(calculation);
            setHasCalculated(true);

            const inputValue = parseFloat(calculation);
            if (!isNaN(inputValue) && conversionRates[outputCurrency]) {
                const convertedValue = (inputValue * conversionRates[outputCurrency]).toFixed(2);
                setConvertedAmount(convertedValue);
            }
        } catch {
            setInput('Error');
        }
    };

    const handleClear = () => {
        if (hasCalculated || input === '') {
            setInput('');
            setConvertedAmount(''); // Limpiar el valor convertido
            setHasCalculated(false);
        } else {
            setInput((prev) => prev.slice(0, -1));
        }
    };

    const toggleMenu = () => setShowMenu((prev) => !prev); // Muestra/oculta el menú

    const selectOption = (option) => {
        setSelectedOption(option);
        setShowMenu(false); // Oculta el menú después de seleccionar
    };

    // Función para cambiar la moneda de entrada
    const handleInputCurrencyChange = (e) => {
        setInputCurrency(e.target.value);
        setConvertedAmount('');
    };

    // Función para cambiar la moneda de salida
    const handleOutputCurrencyChange = (e) => {
        setOutputCurrency(e.target.value);
        setConvertedAmount('');
    };

    return (
        <div className={Style.calculator}>
            <div className={`${Style.calculatorDisplay} ${selectedOption === 'Convert' ? Style.tallerDisplay : ''}`}>
                <div className={Style.calculatorInput}>
                    <div className={Style.calculatorInputB}>
                        {selectedOption === 'Basic' ? (
                            // Mostrar valor sin moneda en "Basic"
                            input ? `${input}` : `0`
                        ) : (
                            <>
                                <select
                                    value={inputCurrency}
                                    onChange={handleInputCurrencyChange}
                                    className={Style.selectCurrency}
                                >
                                    <option value="MXN">MXN</option>
                                    <option value="USD">USD</option>
                                    <option value="CAD">CAD</option>
                                    <option value="EUR">EUR</option>
                                </select>
                                {input ? `${inputCurrency}: ${input}` : `${inputCurrency}: 0`}
                            </>
                        )}
                    </div>
                    {selectedOption === 'Convert' && (
                        <div className={Style.calculatorConvertedAmount}>
                            <select
                                value={outputCurrency}
                                onChange={handleOutputCurrencyChange}
                                className={Style.selectCurrency}
                            >
                                <option value="USD">USD</option>
                                <option value="CAD">CAD</option>
                                <option value="EUR">EUR</option>
                                <option value="MXN">MXN</option>
                            </select>
                            {convertedAmount ? `${outputCurrency}: ${convertedAmount}` : `${outputCurrency}: 0`}
                        </div>
                    )}
                </div>
            </div>
            {selectedOption === 'Basic' && (
                <div className={Style.calculatorButtons}>
                    <button className={Style.calcButton} onClick={handleClear}>
                        {hasCalculated ? 'AC' : <LuDelete />}
                    </button>
                    <button className={Style.calcButton} onClick={() => handleInput('+/-')}>
                        <BsPlusSlashMinus />
                    </button>
                    <button className={Style.calcButton} onClick={() => handleInput('%')}>%</button>
                    <button className={Style.operation} onClick={() => handleInput('/')}>÷</button>
                    <button className={Style.calcButton} onClick={() => handleInput('7')}>7</button>
                    <button className={Style.calcButton} onClick={() => handleInput('8')}>8</button>
                    <button className={Style.calcButton} onClick={() => handleInput('9')}>9</button>
                    <button className={Style.operation} onClick={() => handleInput('*')}>x</button>
                    <button className={Style.calcButton} onClick={() => handleInput('4')}>4</button>
                    <button className={Style.calcButton} onClick={() => handleInput('5')}>5</button>
                    <button className={Style.calcButton} onClick={() => handleInput('6')}>6</button>
                    <button className={Style.operation} onClick={() => handleInput('-')}>−</button>
                    <button className={Style.calcButton} onClick={() => handleInput('1')}>1</button>
                    <button className={Style.calcButton} onClick={() => handleInput('2')}>2</button>
                    <button className={Style.calcButton} onClick={() => handleInput('3')}>3</button>
                    <button className={Style.operation} onClick={() => handleInput('+')}>+</button>
                    <div className={Style.dropdownContainer}>
                        <button className={Style.calcButton} onClick={toggleMenu}>
                            <FaCalculator />
                        </button>
                        {showMenu && (
                            <div className={Style.dropdownMenu}>
                                <button
                                    className={selectedOption === 'Basic' ? Style.selectedOption : ''}
                                    onClick={() => selectOption('Basic')}
                                >
                                    Basic
                                </button>
                                <button
                                    className={selectedOption === 'Convert' ? Style.selectedOption : ''}
                                    onClick={() => selectOption('Convert')}
                                >
                                    Convert
                                </button>
                            </div>
                        )}
                    </div>
                    <button className={Style.calcButton} onClick={() => handleInput('0')}>0</button>
                    <button className={Style.calcButton} onClick={() => handleInput('.')}>.</button>
                    <button className={Style.operation} onClick={calculateResult}>=</button>
                </div>
            )}
            {selectedOption === 'Convert' && (
                <div className={Style.calculatorButtons}>
                    <button className={Style.calcButton} onClick={handleClear}>
                        {hasCalculated ? 'AC' : <LuDelete />}
                    </button>
                    <button className={Style.calcButton} onClick={() => handleInput('+/-')}>
                        <BsPlusSlashMinus />
                    </button>
                    <button className={Style.calcButton} onClick={() => handleInput('%')}>%</button>
                    <button className={Style.operation} onClick={() => handleInput('/')}>÷</button>
                    <button className={Style.calcButton} onClick={() => handleInput('7')}>7</button>
                    <button className={Style.calcButton} onClick={() => handleInput('8')}>8</button>
                    <button className={Style.calcButton} onClick={() => handleInput('9')}>9</button>
                    <button className={Style.operation} onClick={() => handleInput('*')}>x</button>
                    <button className={Style.calcButton} onClick={() => handleInput('4')}>4</button>
                    <button className={Style.calcButton} onClick={() => handleInput('5')}>5</button>
                    <button className={Style.calcButton} onClick={() => handleInput('6')}>6</button>
                    <button className={Style.operation} onClick={() => handleInput('-')}>−</button>
                    <button className={Style.calcButton} onClick={() => handleInput('1')}>1</button>
                    <button className={Style.calcButton} onClick={() => handleInput('2')}>2</button>
                    <button className={Style.calcButton} onClick={() => handleInput('3')}>3</button>
                    <button className={Style.operation} onClick={() => handleInput('+')}>+</button>
                    <div className={Style.dropdownContainer}>
                        <button className={Style.calcButton} onClick={toggleMenu}>
                            <FaCalculator />
                        </button>
                        {showMenu && (
                            <div className={Style.dropdownMenu}>
                                <button
                                    className={selectedOption === 'Basic' ? Style.selectedOption : ''}
                                    onClick={() => selectOption('Basic')}
                                >
                                    Basic
                                </button>
                                <button
                                    className={selectedOption === 'Convert' ? Style.selectedOption : ''}
                                    onClick={() => selectOption('Convert')}
                                >
                                    Convert
                                </button>
                            </div>
                        )}
                    </div>
                    <button className={Style.calcButton} onClick={() => handleInput('0')}>0</button>
                    <button className={Style.calcButton} onClick={() => handleInput('.')}>.</button>
                    <button className={Style.operation} onClick={calculateResult}>=</button>
                </div>
            )}
        </div>
    );
};

export default Calculator;
