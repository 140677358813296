import React from 'react'
import Style from '../assets/css/youtube.module.css';

function Youtube() {
    return (
        <div className={Style.iframeContainer}>
            <iframe
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="Youtube Video"
                width="100%"
                height="600px"
                style={{ border: 'none'}}
            ></iframe>
        </div>
    )
}

export default Youtube
