import React from 'react';
import Style from '../assets/css/sponsor.module.css';

const Sponsor = () => {

    return (
        <div className={Style.sponsorContainer}>
            <h2>Support Me!</h2>
            <div className={Style.kofi}>
                <a
                    href="https://ko-fi.com/omaracruzrmz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Style.koFiButton}
                >
                    <img
                        src="./ko-fi.webp"
                        alt="Ko-fi"
                        className={Style.koFiImage}
                    />
                    buy me a coffee
                </a>
            </div>
        </div>
    );
};

export default Sponsor;
